import React, { useState } from "react"
import useTypingGame from "react-typing-game-hook"
import helpers from "../helpers.js"
import {
  TextField,
  Container,
  Button,
  Typography,
  Grid,
} from "@material-ui/core"

const TypingGameComponent = () => {
  const [lastError, setLastError] = useState(0)
  const [audio, setAudio] = useState(
    new Audio("https://www.myinstants.com/media/sounds/erro.mp3")
  )
  // Call the hook
  const {
    states: { chars, charsState, errorChar },
    actions: { insertTyping, resetTyping, deleteTyping },
  } = useTypingGame(helpers.shuffledWords)

  if (errorChar > lastError) {
    console.log("error")
    audio.play()
    setLastError(errorChar)
  }
  // Capture and display!
  return (
    <Container
      maxWidth="sm"
      style={{ textAlign: "center" }}
      // onKeyDown={(e) => {
      //   const key = e.key
      //   if (key === "Escape") {
      //     resetTyping()
      //   } else if (key === "Backspace") {
      //     deleteTyping(false)
      //   } else if (key.length === 1) {
      //     insertTyping(key)
      //   }
      //   e.preventDefault()
      // }}
      tabIndex={0}
    >
      <div>
        <TextField
          style={{ textAlign: "center" }}
          fullWidth={true}
          onKeyDown={(e) => {
            const key = e.key
            if (key === "Escape") {
              resetTyping()
            } else if (key === "Backspace") {
              deleteTyping(false)
            } else if (key.length === 1) {
              insertTyping(key)
            }
            e.preventDefault()
          }}
        />
      </div>

      {chars.split("").map((char, index) => {
        let state = charsState[index]
        let color = state === 0 ? "black" : state === 1 ? "green" : "red"
        return (
          <span
            key={char + index}
            style={{ color, fontFamily: "Georgia", fontSize: 30 }}
          >
            {char}
          </span>
        )
      })}
    </Container>
  )
}
export default TypingGameComponent
